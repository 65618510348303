module.exports = [{
      plugin: require('/Users/zulfiqaralisoomro/Documents/Projects/gatsby-starter-saas-marketing/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-36835014-3","head":true,"anonymize":true},
    },{
      plugin: require('/Users/zulfiqaralisoomro/Documents/Projects/gatsby-starter-saas-marketing/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Commerce2x","short_name":"Commerce2x","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('/Users/zulfiqaralisoomro/Documents/Projects/gatsby-starter-saas-marketing/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
